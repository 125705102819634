<template>
  <div class="content">
    <h1 class="text">去授权</h1>
    <div class="button" @click="handle">
        授权按钮
    </div>
  </div>
</template>

<script>
// import {macApi} from './fetch'
export default {
  name:'',
  components:{
  },
  data() {
    return {
      redirectUrl:'',
      code: this.$route.query.code
    }
  },
  computed: {
},
async created () {
   
    
  },
  mounted(){
   
  },  
  methods: {
    // 第一次授权
    async handle() {
        this.redirectUrl = `${process.env.VUE_APP_DOMAIN_URL}/outServe`
        const state = Math.random().toString(36).slice(2);
        const url = `${process.env.VUE_APP_AUTH_API_ROOT}/auth/oauth2/authorize?client_id=${process.env.VUE_APP_CLIENT_ID}&redirect_uri=${this.redirectUrl}&response_type=code&state=${state}&scope=all`
        window.location.href = url
    },

 
    
  

    async  getAuthTokenByCode () {
      const res = await fetch(`/macos/mac/token?code=${this.code}`)
      const resData = res.json()
      console.log(resData)
    }

    
  },
}
</script>

<style lang='scss' scoped>
.content {
  margin: 10px auto;
}
.button {
  width: 100px;
  height: 40px;
  background-color: #258aff;
  line-height: 40px;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
}
</style>
